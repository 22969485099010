import React, { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetOneOrganizationQuery } from 'store/organizations/organizations.api'
import { SettingsAttributes } from 'store/settings/settings.api'
import { Scope } from 'store/store.types'

const OrgSettings = createContext<SettingsAttributes | any>({})

interface OrgSettingsProviderProps {
  children: React.ReactNode
}

export const OrgSettingsProvider = (props: OrgSettingsProviderProps) => {
  const { organizationId } = useParams<Scope>()

  const { data: organization } = useGetOneOrganizationQuery(organizationId)
  const ssoEnabled = organization?.data.configuration.single_sign_on?.enabled === true

  const states = organization?.data.configuration?.applicant_states?.states ?? []
  const aliases = useMemo(
    () => states.reduce((acc, state) => ({ ...acc, [state.name]: state.aliases }), {}),
    [states]
  )

  const orgSettings = {
    ssoEnabled,
    aliases: states.length ? aliases : null,
  }

  return <OrgSettings.Provider value={orgSettings}>{props.children}</OrgSettings.Provider>
}

export function useOrgSettings() {
  return useContext(OrgSettings)
}
